<script>
import Layout from "../../layouts/main";
import TradeServiceApi from "@/service/api/trade";
import Progress from "@/service/transactionType";
export default {
  components: { Layout },
  data() {
    return {
      tradeData: null,
      fields: [
        { key: "id", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "created_at", sortable: false },
        { key: "status", sortable: false },
      ],
      rows: 0,
      perPage: 10,
      progress: Progress.trade,
      processedData: null,
      currentPage: 1,
    };
  },
  computed: {},
  mounted() {
    this.loadTradeData();
  },
  watch: {
    currentPage(val) {
      this.loadTradeData(val);
    },
  },
  methods: {
    loadTradeData(page = 1) {
      TradeServiceApi.getAllTrades(page).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.tradeData = response.data.trades.data;
          this.processedData = this.processData();
          this.rows = response.data.trades.total;
        }
      });
    },
    openTrades() {
      console.log("Hello");
    },
    processData() {
      const data = this.tradeData;
      let formattedData = [];
      data.map((item) => {
        const giver_size = item.trade_items.map((size) => {
          return size.giver_size;
        });
        const receiver_size = item.trade_items.map((size) => {
          return size.receiver_size;
        });
        formattedData.push({
          id: item.id,
          created_at: item.created_at,
          giver_amount: item.giver_amount,
          receiver_amount: item.receiver_amount,
          status: item.status,
          receiver_size: receiver_size.filter((item) => item),
          giver_size: giver_size.filter((item) => item),
        });
      });
      return formattedData;
    },
    progressLabel(progress_id) {
      const label = this.progress[progress_id].label;
      const status = this.progress[progress_id].status;
      const color = this.progress[progress_id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    stripProductTitle(text) {
      const length = 25;
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="table-responsive mb-0">
              <b-table :items="tradeData" :fields="fields" responsive="sm">
                <template v-slot:cell(giver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                </template>
                <template v-slot:cell(receiver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                </template>
                <template v-slot:cell(created_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
              </b-table>
            </div> -->
            <div class="d-flex flex-wrap justify-content-center">
              <div
                class="trade-card"
                v-for="trade in processedData"
                :key="trade.id"
                @click="openTrades"
              >
                <div class="text-center m-2">
                  {{ moment(trade.created_at).format("MMMM Do YYYY") }}
                  <div>
                    {{ moment(trade.created_at).format("h:mm a") }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <b-carousel
                      :id="'carousel' + trade.id"
                      :interval="4000"
                      indicators
                      class="m-1"
                    >
                      <!-- Text slides with image -->
                      <b-carousel-slide
                        v-for="(giver, index) in trade.giver_size"
                        :key="index"
                        :img-src="giver.product.image"
                      >
                        <div class="trade-card-text">
                          <div>
                            {{ stripProductTitle(giver.product.name) }}
                          </div>
                          <div>
                            {{ giver.size }}
                          </div>
                        </div>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="col-2">
                    <img
                      class="img-fluid mt-4"
                      src="@/assets/images/arrow.png"
                      alt=""
                    />
                    <div style="width:100px;">
                      <div
                        style="font-size:12px;margin-top:10px;color:green;"
                        v-if="trade.giver_amount"
                      >
                        + AED {{ trade.giver_amount }}
                      </div>
                      <div
                        style="font-size:12px;margin-top:10px;color:red;"
                        v-if="trade.receiver_amount"
                      >
                        - AED {{ trade.receiver_amount }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <b-carousel
                      :id="'carousel-receiver' + trade.id"
                      :interval="4000"
                      indicators
                      class="m-1"
                    >
                      <!-- Text slides with image -->
                      <b-carousel-slide
                        v-for="(receiver, index) in trade.receiver_size"
                        :key="index"
                        :img-src="receiver.product.image"
                      >
                        <div class="trade-card-text">
                          <div>
                            {{ stripProductTitle(receiver.product.name) }}
                          </div>
                          <div>
                            {{ receiver.size }}
                          </div>
                        </div>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin:auto;padding:50px;">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.trade-card {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 285px;
  margin: 5px;
  width: 420px;
  border-radius: 30px;
}

.trade-card-text :first-child {
  font-weight: 100;
  font-size: 14px;
}
.trade-card ol.carousel-indicators {
  bottom: 0px;
}
.trade-card .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
}
.trade-card .carousel-caption {
  bottom: -95px;
  position: absolute;
  color: #000;
}

.trade-card .carousel-inner {
  height: 230px;
}
.trade-card .carousel-item img {
  height: 100px;
}

.trade-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>
