import axios from "axios";
import authHeader from '../auth-header'
import ConfigService from "../config";

const API_URL = ConfigService.base_url;

class TradeServiceApi {
  getAllActiveTrade() {
    return axios
      .get(API_URL + "admin/trades", {
        params:{ 
          type:'accepted',
          paginate:100
        },
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllTrades(page=1){
    return axios
    .get(API_URL + "admin/trades", {
      params:{ 
        paginate:8,
        page:page
      },
      headers: authHeader()
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }

}

export default new TradeServiceApi();
